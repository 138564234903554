import { round } from "lodash";
import { IntlShape } from "react-intl";

export const formatNumber = (intl: IntlShape, num: number, decimalDigit: number = 2) => {
    return new Intl.NumberFormat(intl.locale, { minimumFractionDigits: decimalDigit, maximumFractionDigits: decimalDigit }).format(round(num, decimalDigit));
}

export const formatNumberWithCurrency = (intl: IntlShape, num: number, decimalDigit: number = 2, curreny: string = 'MYR') => {
    return new Intl.NumberFormat(intl.locale, { style: 'currency', currency: curreny, minimumFractionDigits: decimalDigit, maximumFractionDigits: decimalDigit }).format(round(num, decimalDigit));
}

export const formatNumberWithPercentage = (intl: IntlShape, num: number, decimalDigit: number = 2) => {
    return new Intl.NumberFormat(intl.locale, { style: 'percent', minimumFractionDigits: decimalDigit, maximumFractionDigits: decimalDigit }).format(num );
}