import { gql } from '@apollo/client';

export const GET_INDICATOR = gql`
query Indicators{
  indicators{
      parentId,
      id,
      isShowIndicator,
      indicatorCount
  }
}
`;
