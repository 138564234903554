import { Layout } from 'antd';
import './GeneralFooter.less';
const { Footer } = Layout;

const GeneralFooter = () => {
    return (
        <Footer className="site-layout-footer">©2023 . (1395512-U). All rights reserved. Powered by Sigma Coders</Footer>
    )
}

export default GeneralFooter;